.datepickerWithInput .date-picker-full-width {
    width: 100%;
    background:transparent;
    height: 40px;
    padding: 0.625rem;
    text-align: start;
    background-color: rgb(250, 250, 250);
    
}
.datepickerWithInput .react-datepicker-wrapper {
    display: unset;
}

.datepickerWithInput body[direction*="rtl"] .was-validated .form-control:invalid, body[direction*="rtl"] .form-control.is-invalid {
    background: unset !important;
}

.datepickerWithInput .was-validated .form-control:invalid, .form-control.is-invalid {
    background: unset !important;
}

.datepickerWithInput .react-datepicker__input-container {
    position: unset;
}