// handling radio grid and flex
.radio-custom-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  @media (max-width: 576px) {
    display: flex !important;
  }
}
.login-button-justify-content {
  justify-content: start;
  @media (max-width: 576px) {
    justify-content: center;
  }
}
// handling children space grid card
.grid-children-space {
  @media (min-width: 400px) {
    & > * {
      max-width: 447.33px;
      min-width: 384px;
    }
  }
  margin-inline: -10;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 24px;
  @media (max-width: 1625px) {
    grid-template-columns: auto auto;
  }
  @media (max-width: 576px) {
    display: block !important;
  }
  @media (max-width: 384px) {
    display: block !important;
    max-width: 90vw;
    & > * {
      max-width: 90vw;
    }
  }
}

.grid-dashboard {
  margin-inline: -10;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  @media (max-width: 1625px) {
    grid-template-columns: 2fr 1fr;
  }
  @media (max-width: 1216px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.grid-rdv {
  display: flex;
  justify-content: space-between;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
      margin-bottom: 10px;
      min-width: 100%;
      align-items: center;
    }
    & > :last-child {
      margin-bottom: 0px;
    }
  }
}

// drop down in show view
.card-edit-menu ul > li > svg > path {
  color: #757575;
}
.card-edit-menu ul > li > div {
  color: #757575;
}
// label style
label.custom-label-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: "#001018";
}
label.custom-label-style > span {
  color: #e02d39;
}
// radio inout customization
input[type="radio"] {
  display: none;
}
input[type="radio"] + label:before {
  content: "";
  /* create custom radiobutton appearance */
  display: inline-flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  margin-right: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #7c7c7c;
  background-color: #ffffff;
  border-radius: 50%;
}
body[dir="rtl"] {
  input[type="radio"] + label:before {
    content: "";
    margin-left: 6px;
    /* background-color only for content */
  }
}
/* appearance for checked radiobutton */
input[type="radio"]:checked + label:before {
  background-color: #e02d39;
}

input[type="radio"][disabled]:checked + label:before {
  background-color: #7c7c7c;
  // cursor: not-allowed;
}
/* optional styles, I'm using this for centering radiobuttons */
label {
  display: flex;
  align-items: center;
  justify-content: center;
}
iframe {
  min-width: 100% !important;
}

//table custom style
div[class*="react-bootstrap-table"] {
  table {
    thead {
      background-color: #dc3545 !important;
    }
    tbody {
      background-color: #f2f2f2 !important;
    }
  }
}

.select-filter {
  display: flex;
  justify-content: start;
  gap: 16px;
  align-items: center;
  & > * {
    min-width: 316.33px;
  }
  & > *[type="button"] {
    min-width: 0px;
    max-height: 40px;
    color: #ffffff !important;
    background-color: #a1a1a1 !important;
    border-color: #a1a1a1 !important;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #757575 !important;
      border-color: #757575 !important;
    }
    &:active {
      background-color: #757575 !important;
      border-color: #757575 !important;
    }
  }
}

.is-INVALID {
  border-bottom: 1px solid #e02d39;
}

.MuiBreadcrumbs-separator {
  color: #29366a;
  font-size: 25px;
  position: relative;
  top: -2px;
}

div.custom-subLabel-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1b4883;
}

.table.table-head-custom thead th {
  color: #ffffff !important;
  text-transform: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 1.2rem;
}

div[class*="react-bootstrap-table"] {
  table {
    thead {
      background-color: #dc3545 !important;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      border-color: #dc3545;
      th {
        text-align: center !important;
      }
    }
    tbody {
      background-color: #f4f4f4 !important;

      td {
        text-align: center !important;
      }
    }
  }
}

div[class*="measure-table"] {
  table {
    cursor: pointer;
    flex: 1;
    thead {
      background-color: #dc3545 !important;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #dc3545;
      z-index: 1;
      th {
        text-align: center !important;
      }
    }
    tbody {
      background-color: #ffffff !important;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #7c7c7c;

      td {
        text-align: center !important;
      }
    }
  }
}

.done {
  background-color: #b9f6d3 !important;
}

.notDone {
  background-color: #ffffff !important;
}

.table.table-head-custom thead th {
  font-weight: 400;
  color: #ffffff !important;
  text-transform: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.2rem;
}

.disabled {
  background-color: #d6d6d6 !important;
}

.scrollable {
  max-height: 489px;
}

.scrollable-map {
  max-height: 49rem;
}

.border-radius-0{
  border-radius: 0%;
}

.responsive-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.outline-button {
  background-color: transparent !important;
  color: #e02d39 !important;
  border-color: #e02d39 !important;
  font-weight: 600;
}

$gaps: (6, 8, 12, 16, 18, 24, 32, 40, 48);
@each $gap in $gaps {
  .gap-#{$gap} {
    gap: #{$gap}px;
    @media (max-width: 576px) {
      gap: 0px;
    }
  }
}

$gaps: (6, 8, 12, 16, 18, 24, 32, 40, 48);
@each $gap in $gaps {
  .gaps-#{$gap} {
    gap: #{$gap}px;
  }
}

.svg-icon.svg-icon-darkBlue svg g [fill] {
  transition: fill 0.3s ease;
  fill: #1b4883 !important;
}

$flex: 4;
@for $i from 1 through $flex {
  .flex-#{$i} {
    flex: $i;
    @media (max-width: 576px) {
      flex: 0;
    }
  }
}

$fontSize: 24;
@for $i from 1 through $fontSize {
  .fontSize-#{$i} {
    font-size: #{$i}px;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}

.Work-Sans {
  font-family: "Work Sans", sans-serif;
}

.sub-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #303030;
}

// .shadow{
//   -webkit-filter: drop-shadow( 4px 4px 2px rgba(0, 0, 0, 0.5));
//   filter: drop-shadow( 4px 4px 2px rgba(0, 0, 0, 0.5));

// }

.flip-Position {
  margin-top: -295px !important;
}

///download button

.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: #0f1923;
  cursor: pointer;
  position: relative;
  padding: 8px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.15s ease;
}

.button::before,
.button::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 5px);
  border: 1px solid #7d8082;
  transition: all 0.15s ease;
}

.button::before {
  top: 0;
  border-bottom-width: 0;
}

.button::after {
  bottom: 0;
  border-top-width: 0;
}

.button:active,
.button:focus {
  outline: none;
}

.button:active::before,
.button:active::after {
  right: 3px;
  left: 3px;
}

.button:active::before {
  top: 3px;
}

.button:active::after {
  bottom: 3px;
}

.button_lg {
  position: relative;
  display: block;
  padding: 10px 20px;
  color: #fff;
  background-color: #0f1923;
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.button_lg::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: #0f1923;
}

.button_lg::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 4px;
  background-color: #0f1923;
  transition: all 0.2s ease;
}

.button_sl {
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  left: -8px;
  width: 0;
  background-color: #ff4655;
  transform: skew(-15deg);
  transition: all 0.2s ease;
}

.button_text {
  position: relative;
}

.button:hover {
  color: #0f1923;
}

.button:hover .button_sl {
  width: calc(100% + 15px);
}

.button:hover .button_lg::after {
  background-color: #fff;
}

.glow {
  position: relative;
  color: #e02d39;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  outline: none;
  animation: animate 3s linear infinite;
}

@keyframes animate {
  0%,
  50.1%,
  80%,
  92% {
    color: black;
    box-shadow: none;
    opacity: 0.5;
  }
  18.1%,
  50%,
  80.1%,
  92.1% {
    color: #e02d39;
    opacity: 1;
  }
}

.medical-folder-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 0.2fr);
  gap: 1rem;
  @media (max-width: 810px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    row-gap: 1rem;
  }
}

.folder-card-header {
  grid-area: 1 / 1 / 2 / 3;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}
.growth-table-title {
  grid-area: 2 / 1 / 3 / 3;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}
.data-table-growth {
  grid-area: 3 / 1 / 5 / 2;
  margin-top: -24px;
  margin-bottom: 24px;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}

.growth-table-button {
  grid-area: 5 / 1 / 6 / 3;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}

.mapi-card {
  grid-area: 3 / 2 / 4 / 3;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}

.allergy-card {
  grid-area: 4 / 2 / 5 / 3;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}

.growth-graph-title {
  grid-area: 6 / 1 / 7 / 3;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}

.growth-graph {
  grid-area: 7 / 1 / 8 / 3;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}

.head-circumference-table-title {
  grid-area: 8 / 1 / 9 / 3;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}
.data-table-head-circumference {
  grid-area: 9 / 1 / 10 / 3;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}

.head-circumference-table-button {
  grid-area: 10 / 1 / 11 / 3;
  margin-top: 15px;
  @media (max-width: 810px) {
    grid-area: auto;
  }
}

.modal-dialog.modal-mapi {
  max-width: 80vw !important;
}

.marker {
  all: initial;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  svg {
    all: unset;
  }
}

.counter-container {
  max-width: 200px;
  padding: 0.65rem 1rem;
  margin-bottom: 0;
}

hr.new5 {
  border: 1px solid #cdcdcd;
  flex: 1;
}

.breadcrumb-fixed {
  @media (min-width: 992px) {
    background-color: #fafafa;
    padding: auto;
    position: fixed;
    top: 32px + 80px;
    right: 54px;
    left: 0;
    z-index: 50;
    .aside-enabled & {
      left: get($aside-config, base, width) + 48px;
    }

    .aside-minimize-hover.aside-minimize & {
      left: get($aside-config, base, minimized-width) + 48px;
    }
  }
  position: fixed;
  background-color: #fafafa;
  padding-inline-start: 10px;
  top: 55px;
  right: 0;
  left: 0;
  z-index: 1;
}

.breadcrumb-fixed {


  .aside-enabled[dir*="rtl"] & {
    right: get($aside-config, base, width) + 48px;
    left: 0;
  }

  .aside-minimize-hover[dir*="rtl"] & {
    right: get($aside-config, base, minimized-width) + 48px;
    left: 54px;
  }

  .aside-minimize[dir*="rtl"] & {
    right: get($aside-config, base, minimized-width) + 48px;
    left: 54px;
  }

  body[dir*="rtl"] & {
    background-color: #fafafa;
    padding: auto;
    position: fixed;
    top: 32px + 80px;
    left: 54px;
    right: 0;
    z-index: 50;

    @media (max-width: 991px) {
      position: fixed;
      background-color: #fafafa;
      padding-inline-start: 10px;
      top: 55px;
      right: 0 !important;
      left: 0 !important;
      z-index: 1;
  }
  }

}



.vaccination-cards-grid{
  display : grid;
  margin-top: 3rem;
  grid-template-columns: repeat(4,auto);
  gap: 0.68rem;
  .card-header{
    margin-top: 1.6rem;
  }
  @media (max-width:1000px) {
    .card-header{
      margin-top: 1.6rem;
    }
    grid-template-columns: repeat(3,auto);
  }
  @media (max-width:520px) {
    .card-label{
      margin-top: 1.3rem;
    }
    .card-header{
      margin-top: 1.6rem;
    }
    display: flex;
    flex-direction: column;
    margin-inline: 1rem;
    gap: 1rem;
  }

}
.vaccination-cards-grid > * > *{
  height:10rem;  
  .card-label{
    color:gray !important;
    margin: auto;
    font-size: 20px !important;
    // -webkit-text-stroke: 0.3px black;
  }
}

.vaccination-routine-card{
  background-image: linear-gradient(to right,white,rgba(209,235,254,0.5));
  body[dir*="rtl"] &{
    background-image: linear-gradient(to left,white,rgba(209,235,254,0.5));

  }
  // filter: drop-shadow(0 5mm 3mm rgba(209,235,254,1));
  @media (max-width:520px) {
    // filter: drop-shadow(0 2mm 3mm rgba(209,235,254,1));
  }
}

.vaccination-seasonal-card{
  background-image: linear-gradient(to right,white, rgba(199,199,204,0.5));
  body[dir*="rtl"] &{
    background-image: linear-gradient(to left,white, rgba(199,199,204,0.5));

  }
  // filter: drop-shadow(0 5mm 3mm rgba(199,199,204,1));
  @media (max-width:520px) {
    // filter: drop-shadow(0 2mm 3mm rgba(199,199,204,1));
  }
}

.vaccination-occasional-card{
  background-image: linear-gradient(to right,white, rgba(255,179,175,0.5));
  body[dir*="rtl"] &{
    background-image: linear-gradient(to left,white, rgba(255,179,175,0.5));

  }
  // filter: drop-shadow(0 5mm 3mm rgba(255,179,175,1));
  @media (max-width:520px) {
    // filter: drop-shadow(0 2mm 3mm rgba(255,179,175,1));
  }
}

.vaccination-antiRabies-card{
  background-image: linear-gradient(to right,white, rgba(164,233,185,0.5));
  body[dir*="rtl"] &{
    background-image: linear-gradient(to left,white, rgba(164,233,185,0.5));

  }
  // filter: drop-shadow(0 5mm 3mm rgba(164,233,185,1));
  @media (max-width:520px) {
    // filter: drop-shadow(0 2mm 3mm rgba(164,233,185,1));
  }
}

.secondary{
  font-weight: 700;
  font-family: "Open Sans";
  font-style:normal;
  display:inline;
  color: #000000;
  font-size: .875rem,
}


.dci-label-fluid{
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}

